<template>
  <div class="dynamic">
    <div class="header">
      <el-select
          v-model="type"
          clearable
          placeholder="类型"
          class="filter-item"
          style="width: 150px;margin-right: 5px"
          @change="toQuery"
          size="small"
      >
        <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-select
          v-model="status"
          clearable
          placeholder="状态"
          class="filter-item"
          style="width: 150px;margin-right: 5px"
          @change="toQuery"
          size="small"
      >
        <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-button
          v-if="getAddFlag()"
          class="add-btn"
          icon="el-icon-search"
          @click.native="searHandle"
          type="primary"
          size="small"
      >搜索</el-button>
      <el-button
        v-if="getAddFlag()"
        class="add-btn"
        icon="el-icon-plus"
        @click.native="addHandle"
        type="primary"
        size="small"
      >新增</el-button>
    </div>
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="类型" label="type">
        <template slot-scope="scope">
          <span>{{scope.row.type == 1? 'banner': '动态'}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="title" label="中文标题/主题"/>
      <el-table-column prop="limitation" label="英文标题">
        <template slot-scope="scope">
          <span>{{getTitleEn(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" />
      <el-table-column prop="redirectUrl" label="跳转链接" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
              size="small"
              type="success"
              v-if="checkPermission(['ADMIN','TDynamicInfo_ALL','TDynamicInfo_EDIT'])"
              @click="editHandle(scope.row)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <add-or-edit-dialog
      :dialog-show="dialogShow"
      @close-dialog="dialogShow = false"
      :row-info="currentRowInfo"
      :is-add="isAdd"
    ></add-or-edit-dialog>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils'
  import addOrEditDialog from '@/components/notificationManager/dynamic/addOrEditDialog'

  export default {
    name:'dynamic',
    components: { addOrEditDialog },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        dialogShow: false,
        isAdd: false,

        currentRowInfo:{},
        type: '',
        status: '',
        statusOptions: [
          {label: '显示',value: 1},
          {label: '不显示',value: 0},
        ],
        typeList: [
          {label: '首页banner',value: 1},
          {label: '侧边公告',value: 2},
        ]
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init();
      })
    },
    methods: {
      parseTime,
      checkPermission,
      getStatus(row){
        switch (row.status) {
          case 1:
            return '显示';
          case 0:
            return '不显示';
        }
      },
      getTitleEn(row){
        return row.titleEn || '-';
      },
      beforeInit() {
        this.url = 'crm/tDynamicInfo'
        this.params = {
          page: this.page,
          size: this.size
        }
        if(this.type){
          this.params.type = this.type;
        }
        if(this.status !== ''){
          this.params.status = this.status;
        }
        return true
      },

      addHandle(){
        this.dialogShow = true;
        this.isAdd = true;
      },
      editHandle(row){
        this.isAdd = false;
        this.currentRowInfo = row;
        this.dialogShow = true;
      },
      getAddFlag(){
        return checkPermission(['ADMIN','TDynamicInfo_ALL','TDynamicInfo_CREATE'
        ]);
      },
      searHandle(){
        this.toQuery();
      },
    }
  }
</script>

<style lang="less" scoped>
.dynamic {
  padding: 30px;
  .header {
    margin-bottom: 20px;
  }
  .add-btn {
  }
}
</style>
