<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialogShow"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="title"
    width="800px"
    class="pay-add-or-edit-dialog"
  >
    <el-form
        size="small"
        label-width="140px"
    >
      <el-form-item
          label="类型"
      >
        <el-radio
            @change="typeChange"
            v-model="type"
            :disabled="typeDisabled"
            :label="1"
        >Banner</el-radio>
        <el-radio
            @change="typeChange"
            v-model="type"
            :disabled="typeDisabled"
            :label="2"
        >动态</el-radio>
      </el-form-item>
    </el-form>
    <div
      v-show="type == 1"
    >
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          size="small"
          label-width="140px"
      >

        <el-form-item
            prop="bannerSubject"
            label="主题"
        >
          <el-input
              v-model="form.bannerSubject"
              placeholder="请输入"
              show-word-limit
              maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
            prop="bannerLink"
            label="跳转链接"
        >
          <el-input
              v-model="form.bannerLink"
              placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
            prop="bannerShowLang"
            label="显示语言"
        >
          <el-radio
              v-model="form.bannerShowLang"
              :label="0"
          >全部</el-radio>
          <el-radio
              v-model="form.bannerShowLang"
              :label="1"
          >中文简体</el-radio>
          <el-radio
              v-model="form.bannerShowLang"
              :label="3"
          >中文繁体</el-radio>
          <el-radio
              v-model="form.bannerShowLang"
              :label="2"
          >英文</el-radio>
        </el-form-item>
        <el-form-item
            v-if="isZh"
            class="two-form-item"
        >
          <!--        :label="`<span class='star'>*</>span>中文图片`"-->
          <p class="two-item-p">
            <span class='star'>*</span>中文简体图片
          </p>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item
                  prop="bannerPcZhImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadBannerPcZhImage"
                      :before-upload="beforeAvatarUpload">
                    <img
                        v-if="form.bannerPcZhImage"
                        :src="form.bannerPcZhImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips
                      class="upload-tips"
                  ></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item
                  prop="bannerMobileZhImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadBannerMoblieZhImage"
                      :before-upload="beforeAvatarUpload">
                    <!--                  :on-success="handleBannerMoblieZhImageSuccess"-->
                    <img
                        v-if="form.bannerMobileZhImage"
                        :src="form.bannerMobileZhImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips class="upload-tips"></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <p class="upload-img-tip text-center">PC端</p>
            </el-col>
            <el-col :span="10">
              <p class="upload-img-tip text-center">移动端</p>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
            v-if="isZhant"
            class="two-form-item"
        >
          <!--        :label="`<span class='star'>*</>span>中文图片`"-->
          <p class="two-item-p">
            <span class='star'>*</span>中文繁体图片
          </p>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item
                  prop="bannerPcZhantImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadBannerPcZhantImage"
                      :before-upload="beforeAvatarUpload">
                    <img
                        v-if="form.bannerPcZhantImage"
                        :src="form.bannerPcZhantImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips class="upload-tips"></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item
                  prop="bannerMobileZhantImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadBannerMoblieZhantImage"
                      :before-upload="beforeAvatarUpload">
                    <img
                        v-if="form.bannerMobileZhantImage"
                        :src="form.bannerMobileZhantImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips class="upload-tips"></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <p class="upload-img-tip text-center">PC端</p>
            </el-col>
            <el-col :span="10">
              <p class="upload-img-tip text-center">移动端</p>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
            v-if="isEnglish"
            class="two-form-item"
        >
          <p class="two-item-p">
            <span class='star'>*</span>英文图片
          </p>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item
                  prop="bannerPcEnImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadBannerPcEnImage"
                      :before-upload="beforeAvatarUpload">
                    <img
                        v-if="form.bannerPcEnImage"
                        :src="form.bannerPcEnImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips class="upload-tips"></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item
                  prop="bannerMobileEnImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadBannerMoblieEnImage"
                      :before-upload="beforeAvatarUpload">
                    <!--                  :on-success="handleBannerMoblieEnImageSuccess"-->
                    <img
                        v-if="form.bannerMobileEnImage"
                        :src="form.bannerMobileEnImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips class="upload-tips"></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <p class="upload-img-tip text-center">PC端</p>
            </el-col>
            <el-col :span="10">
              <p class="upload-img-tip text-center">移动端</p>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
            label="状态"
            prop="bannerStatus"
            hide-required-asterisk
        >
          <el-radio
              v-model="form.bannerStatus"
              :label="1"
          >显示</el-radio>
          <el-radio
              v-model="form.bannerStatus"
              :label="0"
          >不显示</el-radio>
        </el-form-item>
        <!--banner部分结束-->
        <!--动态-->
      </el-form>
    </div>
    <div
      v-show="type == 2"
    >
      <el-form
          ref="dynamicform"
          :model="dynamicForm"
          :rules="dynamicRules"
          size="small"
          label-width="140px"
      >
        <el-form-item
            prop="title"
            label="中文标题"
        >
          <el-input
              v-model="dynamicForm.title"
              placeholder="请输入"
              show-word-limit
              maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
            prop="titleEn"
            label="英文标题"
        >
          <el-input
              v-model="dynamicForm.titleEn"
              placeholder="请输入"
              show-word-limit
              maxlength="60"
          ></el-input>
        </el-form-item>
        <el-form-item
            prop="link"
            label="跳转链接"
        >
          <el-input
              v-model="dynamicForm.link"
              placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
            class="two-form-item"
        >
          <p class="two-item-p dynamic">
            <span class='star'>*</span>封面
          </p>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item
                  prop="zhImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadZhDynamicImage"
                      :before-upload="beforeAvatarUpload">
                    <img
                        v-if="dynamicForm.zhImage"
                        :src="dynamicForm.zhImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips
                      class="upload-tips"
                  ></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item
                  prop="enImage"
              >
                <div class="upload-box pr">
                  <el-upload
                      class="avatar-uploader"
                      action="''"
                      :accept="getLimitUploadImageFormat()"
                      :show-file-list="false"
                      :http-request="uploadEnDynamicImage"
                      :before-upload="beforeAvatarUpload">
                    <img
                        v-if="dynamicForm.enImage"
                        :src="dynamicForm.enImage"
                        class="avatar middle"
                    >
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <uploadImgTips class="upload-tips"></uploadImgTips>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <p class="upload-img-tip text-center">PC端</p>
            </el-col>
            <el-col :span="10">
              <p class="upload-img-tip text-center">移动端</p>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
            label="状态"
            prop="bannerStatus"
            hide-required-asterisk
        >
          <el-radio
              v-model="dynamicForm.status"
              :label="1"
          >显示</el-radio>
          <el-radio
              v-model="dynamicForm.status"
              :label="0"
          >不显示</el-radio>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click.stop="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { uploadFile } from '@/api/data'
import { getLimitUploadImageFormat,limitImageSizeFormat,getBase64, base64toFile } from '@/utils/index'
import { mapGetters } from 'vuex'

import { addDynamic,editDynamic } from '@/api/notificationManager/dynamic'
let assginForm = {

  // banner
  bannerSubject: '', //主题
  bannerLink: '', // 跳转链接
  bannerShowLang: 0,
  bannerPcZhImage: '', // 中文图片 pc端
  bannerMobileZhImage: '', // 中文图片 mobile端

  bannerPcZhantImage: '', //繁体图片,pc端
  bannerMobileZhantImage: '', //繁体图片,pc端

  bannerPcEnImage: '', // 英文图片 pc端
  bannerMobileEnImage: '', // 英文图片 移动端

  bannerStatus: 1, //Banner状态
  // banner
}
let assginDynamicForm = {
  title: '', //标题
  titleEn: '', //英文标题
  link: '', // 跳转链接
  zhImage: '', // 中文图片 pc端
  enImage: '', // 英文图片 pc端
  status: 1, //Banner状态
}
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
    dialogShow:{
      type: Boolean,
      default: false
    },
    rowInfo:{
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      type: 1,
      form: {
        id: '',
        ...assginForm,
      },
      rules:{
        bannerSubject:[
          { required: true, message: '请输入Banner主题', trigger: 'blur' },
        ],
        bannerLink:[
          // { required: true, message: '请输入Banner跳转链接', trigger: 'blur' },
        ],
        bannerPcZhImage:[
          { required: true, message: '请上传PC端中文图片', trigger: 'change' },
        ],
        bannerMobileZhImage:[
          { required: true, message: '请上传移动端中文图片', trigger: 'change' },
        ],

        bannerPcZhantImage:[
          { required: true, message: '请上传PC端中文图片', trigger: 'change' },
        ],
        bannerMobileZhantImage:[
          { required: true, message: '请上传移动端中文图片', trigger: 'change' },
        ],

        bannerPcEnImage:[
          { required: true, message: '请上传PC端英文图片', trigger: 'change' },
        ],
        bannerMobileEnImage:[
          { required: true, message: '请上传移动端英文图片', trigger: 'change' },
        ],
        bannerStatus:[
          // { required: true, message: '选择Banner状态', trigger: 'change' },
        ],
      },
      dynamicForm:{
        id: '',
        ...assginDynamicForm,
      },
      dynamicRules:{
        title:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        titleEn:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        link:[
          // { required: true, message: '请输入Banner跳转链接', trigger: 'blur' },
        ],
        zhImage:[
          { required: true, message: '请上中文版图片', trigger: 'change' },
        ],
        enImage:[
          { required: true, message: '请上传英文版图片', trigger: 'change' },
        ],

        status:[
          // { required: true, message: '选择Banner状态', trigger: 'change' },
        ],
      },
    }
  },
  watch:{
    rowInfo:{
      handler(newValue){
        if(Object.keys(newValue).length > 0 && this.isAdd == false){
          this.assignmentFormCom(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    dialogShow(newValue){
      this.$nextTick(() => {
        if(newValue && this.isAdd){
          this.clearForm();
          this.$refs.form && this.$refs.form.clearValidate();
        }
        if(newValue && !this.isAdd){
          this.assignmentFormCom(this.rowInfo);
          this.$refs.form && this.$refs.form.clearValidate();
        }
      });
    },
  },
  computed: {
    ...mapGetters([]),
    title(){
      return this.isAdd ? '新增': '编辑';
    },
    typeDisabled(){
      return !this.isAdd ? true : false;
    },
    isEnglish(){
      return this.form.bannerShowLang == 0 || this.form.bannerShowLang == 2;
    },
    isZh(){
      return this.form.bannerShowLang == 0 || this.form.bannerShowLang == 1;
    },
    isZhant(){
      return this.form.bannerShowLang == 0 || this.form.bannerShowLang == 3;
    }
  },
  methods: {
    getLimitUploadImageFormat,
    clearForm(){
      this.form = {
        id: '',
        ...assginForm
      };
    },
    cancel() {
      this.close();
    },
    close(){
      this.resetForm();
      this.resetDynamicForm();
      this.$emit('close-dialog');
    },
    beforeCloseHandle(){
      this.close();
    },
    assignmentFormCom(data){
      this.type = data.type;
      if(data.type == 1){
        this.bannerAssign(data);
      }else if(data.type == 2){
        this.dynamicAssign(data);
      }
    },
    bannerAssign(data){
      this.form.id = data.id;
      this.form.bannerSubject = data.title;
      this.form.bannerLink = data.redirectUrl;
      this.form.bannerShowLang = data.availableLang;
      this.form.bannerStatus = data.status;

      if(data.availableLang == 1){
        this.form.bannerPcZhImage = data.imgPc;
        this.form.bannerMobileZhImage = data.imgMobile;
      }else if(data.availableLang == 2){
        this.form.bannerPcEnImage = data.imgPcEn;
        this.form.bannerMobileEnImage = data.imgMobileEn;
      }else if(data.availableLang == 3){
        this.form.bannerPcZhantImage = data.imgPcHant;
        this.form.bannerMobileZhantImage = data.imgMobileHant;

      }else if(data.availableLang === 0){
        this.form.bannerPcZhImage = data.imgPc;
        this.form.bannerMobileZhImage = data.imgMobile;
        this.form.bannerPcEnImage = data.imgPcEn;
        this.form.bannerMobileEnImage = data.imgMobileEn;

        this.form.bannerPcZhantImage = data.imgPcHant;
        this.form.bannerMobileZhantImage = data.imgMobileHant;
      }
    },
    dynamicAssign(data){
      this.dynamicForm.id = data.id;
      this.dynamicForm.title = data.title;
      this.dynamicForm.titleEn = data.titleEn;
      this.dynamicForm.link = data.redirectUrl;

      this.dynamicForm.zhImage = data.imgPc;
      this.dynamicForm.enImage = data.imgPcEn;
      this.dynamicForm.status = data.status;
    },

    uploadFileCommon(item,callback){
      getBase64(item.file).then(res => {

        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          callback && callback(res.data.content[0].url)
        });
      });
    },
    uploadBannerPcZhImage(item){
      this.uploadFileCommon(item,(url) => {
        this.form.bannerPcZhImage = url;
      });

    },
    uploadBannerMoblieZhImage(item){
      this.uploadFileCommon(item,(url) => {
        this.form.bannerMobileZhImage = url;
      });
    },

    uploadBannerPcZhantImage(item){
      this.uploadFileCommon(item,(url) => {
        this.form.bannerPcZhantImage = url;
      });
    },
    uploadBannerMoblieZhantImage(item){
      this.uploadFileCommon(item,(url) => {
        this.form.bannerMobileZhantImage = url;
      });

    },

    uploadBannerPcEnImage(item){
      this.uploadFileCommon(item,(url) => {
        this.form.bannerPcEnImage = url;
      });

    },
    uploadBannerMoblieEnImage(item){
      this.uploadFileCommon(item,(url) => {
        this.form.bannerMobileEnImage = url;
      });

    },
    //动态
    uploadZhDynamicImage(item){
      this.uploadFileCommon(item,(url) => {
        this.dynamicForm.zhImage = url;
      });
    },
    uploadEnDynamicImage(item){
      this.uploadFileCommon(item,(url) => {
        this.dynamicForm.enImage = url;
      });
    },
    beforeAvatarUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    doSubmit() {
      if(this.type == 1){
        this.$refs.form.validate((valid) => {
          if(valid){
            if (this.isAdd) {
              this.doAdd();
            } else this.doEdit();
          }else {
            console.log('error submit!!');
            return false;
          }
        })
      }else {
        this.$refs.dynamicform.validate((valid) => {
          if(valid){
            if (this.isAdd) {
              this.dynamicDoAdd();
            } else this.dynamicDoEdit();
          }else {
            console.log('error submit!!');
            return false;
          }
        })
      }
    },
    getParams(params){
      // banner类型
      params.title = this.form.bannerSubject;
      params.redirectUrl = this.form.bannerLink;
      // 语言
      params.availableLang = this.form.bannerShowLang;
      if(this.form.bannerShowLang == 1){
        params.imgPc = this.form.bannerPcZhImage;
        params.imgMobile = this.form.bannerMobileZhImage;
      }else if(this.form.bannerShowLang == 2){
        params.imgPcEn = this.form.bannerPcEnImage;
        params.imgMobileEn = this.form.bannerMobileEnImage;

      }else if(this.form.bannerShowLang == 3){
        params.imgPcHant  = this.form.bannerPcZhantImage;
        params.imgMobileHant = this.form.bannerMobileZhantImage;

      }else if(this.form.bannerShowLang === 0){

        params.imgPc = this.form.bannerPcZhImage;
        params.imgMobile = this.form.bannerMobileZhImage;
        params.imgPcEn = this.form.bannerPcEnImage;
        params.imgMobileEn = this.form.bannerMobileEnImage;

        params.imgPcHant  = this.form.bannerPcZhantImage;
        params.imgMobileHant = this.form.bannerMobileZhantImage;
      }
      params.status = this.form.bannerStatus;
    },
    doAdd() {
      let params = {
        type: 1,
      };
      this.getParams(params);
      this.loading = true;
      addDynamic(params).then(res => {
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.close();
        this.$parent.init();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      let params = {
        id: this.form.id,
        type: 1,
      }
      this.getParams(params);
      this.loading = true;
      editDynamic(params).then(res => {
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.close();
        this.$parent.init();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.$refs['form'] && this.$refs['form'].clearValidate();
      this.$refs['form'] && this.$refs['form'].resetFields();
      this.form = {
        id: '',
        ...assginForm,
      }
    },

    getDynamicParams(params){
      // banner类型
      params.title = this.dynamicForm.title;
      params.titleEn = this.dynamicForm.titleEn;
      params.redirectUrl = this.dynamicForm.link;

      params.imgPc = this.dynamicForm.zhImage;

      params.imgPcEn = this.dynamicForm.enImage;

      params.status = this.dynamicForm.status;
    },

    dynamicDoAdd() {

      let params = {
          type: 2,
      };
      this.getDynamicParams(params);
      this.loading = true;
      addDynamic(params).then(res => {
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.close();
        this.resetDynamicForm();
        this.$parent.init();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    dynamicDoEdit() {
      let params = {
        id: this.dynamicForm.id,
        type: 2,
      }
      this.getDynamicParams(params);
      this.loading = true;
      editDynamic(params).then(res => {
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.close();
        this.resetDynamicForm();
        this.$parent.init();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetDynamicForm() {
      this.$refs['dynamicform'] && this.$refs['dynamicform'].clearValidate();
      this.$refs['dynamicform'] && this.$refs['dynamicform'].resetFields();
      this.dynamicForm = {
        id: '',
        ...assginDynamicForm,
      }
    },
    typeChange(){
      this.$refs.form.clearValidate();
      this.$refs.dynamicform.clearValidate();
    },
  }
}
</script>
<style lang="less">
  .pay-add-or-edit-dialog {
    .el-dialog__body {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
</style>
<style lang="less" scoped>
.pay-add-or-edit-dialog {

  .upload-box {
    .avatar-uploader {
      width: 100%;
      height: 100px;
      border: 1px solid #c0c0c0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /deep/ .el-upload {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .avatar {
        width: 100%;
        height: 100%;
      }
    }

  }
  .upload-img-tip {
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 20px;
  }
  .el-form-item__label {
    word-break: break-all;
  }
  .two-form-item {
    position: relative;
    .two-item-p {
      position: absolute;
      left: -100px;
      top: 0;
      font-weight: 700;
      &.dynamic {
        left: -50px;
      }
    }
  }

  .star {
    color: red;
  }
}
</style>
